import { useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek"
import timezone from "dayjs/plugin/timezone"

import { Dialog, Radio, RadioGroup, StaffHeading3 } from "@doktor-se/bones-ui"
import { AssignedConversation, CallEndReason, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { assignToQueue } from "api"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch } from "lib/hooks"
import { SearchPageConversation } from "types"

import { ToDigitalQueue } from "./components/ToDigitalQueue"
import ToPhysicalQueue from "./components/ToPhysicalQueue"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import styles from "./PhysicalToDigitalDialog.module.scss"

dayjs.extend(timezone)
dayjs.extend(isoWeek)

export type Day = "today" | "tomorrow" | undefined

export interface ConfirmParams {
  postponed?: string
  clinicId?: string
}

type DialogType = "digital" | "physical"
const dialogOptions = [
  {
    value: "digital",
    translationKey: "control.unassign.option.digital"
  },
  {
    value: "physical",
    translationKey: "control.unassign.option.physical"
  }
]

export interface PhysicalToDigitalDialogProps {
  isOpen: boolean
  onClose: () => void
  offset?: Offset
  conversation: AssignedConversation | SearchPageConversation
}

const PhysicalToDigitalDialog = ({ isOpen, offset, onClose, conversation }: PhysicalToDigitalDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [dialogOption, setDialogOption] = useState<DialogType>("digital")

  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>()

  const onCloseDialog = () => {
    onClose()
  }

  const handleDialogOptionChange = (dialogType: DialogType) => {
    setDialogOption(dialogType)
    setSelectedQueue(undefined)
  }

  const unassignConfirmed = (params: ConfirmParams) => {
    if (!selectedQueue) return
    let reason: CallEndReason | undefined
    if (conversation.callState !== "incoming") reason = checkDeclineCall(conversation)
    dispatch(assignToQueue(conversation, selectedQueue?.queueKey, params.postponed, reason, params.clinicId))
    onCloseDialog()
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <>
        <StaffHeading3 className={styles.heading}>{intl.formatMessage({ id: "unassign.title" })}</StaffHeading3>
        <div className={styles.radioWrapper}>
          <RadioGroup
            label=""
            aria-label="Dialog Type"
            aria-labelledby=""
            defaultValue="digital"
            onChange={value => {
              handleDialogOptionChange(value as DialogType)
            }}>
            {dialogOptions.map(option => (
              <Radio key={option.value} value={option.value}>
                {intl.formatMessage({ id: option.translationKey })}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {dialogOption === "digital" && (
          <ToDigitalQueue
            conversationId={conversation.id}
            selectedQueue={selectedQueue}
            setSelectedQueue={setSelectedQueue}
            onSend={unassignConfirmed}
          />
        )}
        {dialogOption === "physical" && (
          <ToPhysicalQueue
            conversation={conversation}
            selectedQueue={selectedQueue}
            setSelectedQueue={setSelectedQueue}
            onSend={unassignConfirmed}
          />
        )}
      </>
    </Dialog>
  )
}

export default PhysicalToDigitalDialog
