import React from "react"

import classNames from "classnames"

import { StaffLabelTextXS } from "@doktor-se/bones-ui"

import styles from "./ActionPill.module.scss"

interface ActionPillProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  text: string
  onClick: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
  colored?: boolean
}

const ActionPill = ({ Icon, text, onClick, className, colored }: ActionPillProps) => {
  return (
    <button
      data-testid="pill"
      className={classNames(className, styles.container, { [styles.coloredStyle]: colored })}
      onClick={evt => onClick?.(evt)}>
      <Icon className={styles.icon} />
      {text && <StaffLabelTextXS>{text}</StaffLabelTextXS>}
    </button>
  )
}

export default ActionPill
