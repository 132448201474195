import { useIntl } from "react-intl"

import classnames from "classnames"

import { StaffBodyTextXXS } from "@doktor-se/bones-ui"
import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { updateLocalConversationState } from "reducers/selected"

import { IconRightArrow } from "icons"

import styles from "./HistoryJournalRow.module.scss"

interface HistoryJournalRowProps {
  created: string
  category?: Category
  openJournal: () => void
  active?: boolean
}

const HistoryJournalRow = ({ created, category, openJournal, active = false }: HistoryJournalRowProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const conversation = useAppSelector(selectConversation)

  const onClick = () => {
    if (active)
      conversation &&
        dispatch(
          updateLocalConversationState({
            conversationId: conversation.id,
            state: { selectedJournal: undefined }
          })
        )
    else openJournal()
  }

  return (
    <li className={classnames(styles.row, { [styles.active]: active })}>
      <button className={styles.button} onClick={onClick}>
        <SymptomBubble symptom={category} appStatic={appStatic} />
        <StaffBodyTextXXS className={styles.time}>
          {created
            ? `${intl.formatDate(created, { day: "2-digit", month: "2-digit", year: "numeric" })} ${intl.formatTime(
                created
              )}`
            : intl.formatMessage({ id: "label.unknown" })}
        </StaffBodyTextXXS>
        <div className={styles.icon}>
          <IconRightArrow isActive={active} />
        </div>
      </button>
    </li>
  )
}

export default HistoryJournalRow
