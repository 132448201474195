import { useRef, useState } from "react"

import classNames from "classnames"

import { Button, Dialog, ICONS, StaffHeading3, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { TextsCarealot } from "@doktor-se/bones-ui/dist/web-shared/components"
import { useClickOutside } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"

import styles from "./SelectClinic.module.scss"

type ClinicId = string | null
export type SetClinicFunc = (clinicId: ClinicId) => void

interface SelectClinicProps {
  clinics: Clinic[]
  setClinic: SetClinicFunc
  selectedClinic?: Clinic
  clinicTagTexts?: TextsCarealot["clinicTag"]
  listedClinicId?: string
}
const SelectClinic = ({ clinics, setClinic, selectedClinic, clinicTagTexts, listedClinicId }: SelectClinicProps) => {
  const containerRef = useRef(null)
  const [openSelect, setOpenSelect] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [clickedClinicId, setClickedClinicId] = useState<ClinicId>(null)

  useClickOutside(containerRef, () => setOpenSelect(false))

  const onClickClinic = (clinicId: ClinicId) => {
    if (clinicId === listedClinicId || !clinicId) {
      setClinic(clinicId)
    } else {
      setClickedClinicId(clinicId)
      setShowConfirmDialog(true)
    }
    setOpenSelect(false)
  }

  const onConfirm = () => {
    setClinic(clickedClinicId)
    setShowConfirmDialog(false)
  }

  const onCancel = () => {
    setClickedClinicId(null)
    setShowConfirmDialog(false)
  }

  return (
    <div ref={containerRef}>
      <button
        className={classNames(styles.selectButton, {
          [styles.highlighted]: listedClinicId && selectedClinic?.id === listedClinicId
        })}
        onClick={() => setOpenSelect(!openSelect)}>
        <ICONS.Carecenter className={styles.icon} />
        <StaffLabelTextXS as="span" className={styles.label}>
          {selectedClinic?.name || clinicTagTexts?.noClinic || ""}
        </StaffLabelTextXS>
      </button>

      {openSelect && (
        <ul className={styles.selectList}>
          {!listedClinicId && (
            <li>
              <button className={styles.row} onClick={() => onClickClinic(null)}>
                <StaffLabelTextXS as="span">{clinicTagTexts?.noClinic}</StaffLabelTextXS>
              </button>
            </li>
          )}
          {clinics.map((clinic, i) => (
            <li key={i}>
              <button
                className={classNames(styles.row, { [styles.highlighted]: clinic.id === listedClinicId })}
                onClick={() => onClickClinic(clinic.id)}>
                <StaffLabelTextXS as="span">{clinic.name}</StaffLabelTextXS>
              </button>
            </li>
          ))}
        </ul>
      )}

      <Dialog isOpen={showConfirmDialog} onClose={onCancel} closeAriaLabel={clinicTagTexts?.noClinic || ""} fixedWidth>
        <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>{clinicTagTexts?.assignToNotListedClinic}</StaffHeading3>
        <div className={styles.buttonContainer}>
          <Button variant="primary" color="primary" onPress={onConfirm} fullWidth>
            {clinicTagTexts?.confirm}
          </Button>
          <Button variant="secondary" color="primary" onPress={onCancel} fullWidth>
            {clinicTagTexts?.cancel}
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default SelectClinic
