import { useCallback, useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"

import dayjs from "dayjs"

import { Draggable, Warning, WarningAutoClose } from "@doktor-se/bones-ui/dist/web-shared/components"

import {
  categoriesFetch,
  checkPermissions,
  clinicsFetch,
  conversationsConnect,
  fetchAssignedConversations,
  fetchMeetingKinds,
  fetchPersonalBookings,
  fetchPersonalQueue,
  fetchPhrasesData,
  fetchQueueCount,
  fetchQueues,
  logout,
  queueOpeningHoursFetch,
  staffFetch
} from "api"
import { dayjsLocales, featureFlags, freshdeskLanguage, platform } from "config"
import { refreshAuthToken } from "lib/fetch"
import { useAppDispatch, useAppSelector, useCallbackWhenOnlineAgain } from "lib/hooks"
import { opentokInit } from "lib/opentok"
import { handleSealdUserRegistration, sealdInit } from "lib/seald"
import { setBanner, setIsReadyForAssignment, setShowDialNumber, toggleWelcomeMessage } from "reducers/app"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { closeError } from "reducers/error"
import { queueSelectors } from "reducers/queue/queue.reducer"

import Banner from "components/banners/Banner"
import { BookingInboxScheduler } from "components/booking/BookingInboxScheduler"
import WelcomeDialog from "components/LoggedInLayout/components/WelcomeDialog"
import TokenWarning from "components/tokenWarning"
import Call from "pages/call/Call"
import DialNumber from "pages/dashboard/components/ConversationControls/components/DialNumberDialog/DialNumberDialog"

import Navigation from "../navigation/Navigation"
import { NewConversationInInboxNotifier } from "../NewConversationNotifier/NewConversationNotifier"

import styles from "./LoggedInLayout.module.scss"

interface LoggedInLayoutProps {
  children: JSX.Element
}

const LoggedInLayout = ({ children }: LoggedInLayoutProps) => {
  const dispatch = useAppDispatch()
  const bannerState = useAppSelector(state => state.app.bannerState)
  const showingWelcomeMessage = useAppSelector(state => state.app.showingWelcomeMessage)
  const errors = useAppSelector(state => state.error.errors)
  const tokenExpBannerState = useAppSelector(state => state.auth.tokenExpBannerState)
  const bannerTimeout = useRef<ReturnType<typeof setTimeout>>()
  const [delayedBannerState, setDelayedBannerState] = useState(bannerState)
  const callState = useAppSelector(state => state.tokbox.callState)
  const appLanguage = useAppSelector(state => state.app.language)
  const conversation = useAppSelector(selectConversation)
  const showDialNumber = useAppSelector(state => state.app.showDialNumber)
  const dialNumberOffset = useAppSelector(state => state.app.dialNumberOffset)
  const queueIds = useAppSelector(queueSelectors.selectIds)
  const userId = useAppSelector(state => state.app.user.id)
  const sealdSDKInitiated = useAppSelector(state => state.encryption.sealdSDKInitialisationStatus)

  const [wsConnected, setWsConnected] = useState(false)

  const sealdEncryptionFeatureEnabled = useAppSelector(state => state.encryption.encryptionEnabled)

  const connectToWS = useCallback(async () => {
    const response = await dispatch(conversationsConnect())
    setWsConnected(!!response)
    if (bannerState?.type === "reconnect") dispatch(setBanner(undefined))
  }, [bannerState?.type, dispatch])

  const reconnectToWS = useCallback(async () => {
    connectToWS()
    if (queueIds?.length) {
      dispatch(fetchQueueCount(queueIds as string[]))
    }
  }, [connectToWS, dispatch, queueIds])

  useCallbackWhenOnlineAgain(reconnectToWS)

  useEffect(() => {
    if (!wsConnected) return

    dispatch(fetchAssignedConversations())
    dispatch(staffFetch())
    dispatch(checkPermissions())
    dispatch(setIsReadyForAssignment(false))
    dispatch(opentokInit())

    if (featureFlags.has("booking")) {
      dispatch(fetchPersonalBookings())
      dispatch(fetchMeetingKinds())
    }

    if (
      featureFlags.has("clinic_tag") ||
      featureFlags.has("physical_to_digital") ||
      featureFlags.has("to_queue_select_clinic")
    )
      dispatch(clinicsFetch())
    if (featureFlags.has("phrases")) dispatch(fetchPhrasesData())
    if (featureFlags.has("queue_opening_hours")) dispatch(queueOpeningHoursFetch())
  }, [dispatch, wsConnected])

  useEffect(() => {
    dispatch(refreshAuthToken())
  }, [dispatch])

  useEffect(() => {
    if (!wsConnected) return

    dispatch(categoriesFetch())
    dispatch(fetchQueues())
    dispatch(fetchPersonalQueue())
    dayjsLocales[appLanguage]().then(() => {
      dayjs.locale(appLanguage)
    })
  }, [dispatch, appLanguage, wsConnected])

  useEffect(() => {
    if (!wsConnected || !queueIds.length) return
    dispatch(fetchQueueCount(queueIds as string[]))
  }, [dispatch, queueIds, wsConnected])

  useEffect(
    () => () => {
      if (bannerTimeout.current) clearTimeout(bannerTimeout.current)
    },
    []
  )

  useEffect(() => {
    return () => {
      dispatch(setShowDialNumber(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (userId && sealdEncryptionFeatureEnabled) {
      dispatch(sealdInit(userId))
    }
  }, [dispatch, sealdEncryptionFeatureEnabled, userId])

  useEffect(() => {
    if (userId && sealdSDKInitiated && sealdEncryptionFeatureEnabled) {
      dispatch(handleSealdUserRegistration())
    }
  }, [dispatch, sealdEncryptionFeatureEnabled, sealdSDKInitiated, userId])

  const closeModal = () => {
    dispatch(setShowDialNumber(false))
  }

  const callNumberPosition = useCallback(
    (_?: number, clientWidth?: number) => ({
      x: dialNumberOffset.left + (clientWidth || 0) + 80,
      y: dialNumberOffset.top
    }),
    [dialNumberOffset.left, dialNumberOffset.top]
  )

  useEffect(() => {
    if (bannerTimeout.current) clearTimeout(bannerTimeout.current)
    bannerTimeout.current = setTimeout(
      () => {
        setDelayedBannerState(bannerState)
      },
      bannerState ? 1000 : 0
    )
  }, [bannerState])

  useEffect(() => {
    if (!featureFlags.has("freshdesk_chat")) return

    const fcWidget = (window as any).fcWidget

    if (!!fcWidget && !fcWidget.isInitialized()) {
      fcWidget.init({
        token: "2d72a03d-bbc2-4d47-9795-dde6813a01f4",
        host: "https://wchat.eu.freshchat.com",
        siteId: platform,
        locale: freshdeskLanguage
      })
    }

    return () => {
      fcWidget?.destroy()
    }
  }, [])

  return (
    <div className={styles.container}>
      <Navigation />
      {delayedBannerState && (
        <Banner
          type={delayedBannerState.type}
          meta={delayedBannerState.meta}
          callback={() => dispatch(conversationsConnect())}
        />
      )}
      {tokenExpBannerState && <TokenWarning exp={tokenExpBannerState} logout={() => dispatch(logout())} />}
      {children}
      {["active", "outgoing"].includes(callState) && <Call />}
      {showDialNumber && conversation && (
        <Draggable expanded={false} position={callNumberPosition} classNames={styles.dialNumberContainer}>
          <DialNumber closeModal={() => closeModal()} conversation={conversation} />
        </Draggable>
      )}
      {errors.map(
        error =>
          error.show &&
          !error.customError &&
          !error.hide && (
            <Warning
              key={error.id}
              text={
                error.customMessage ? (
                  <FormattedMessage id={error.customMessage} key={error.customMessage} />
                ) : error.localisedKey ? (
                  <FormattedMessage id={error.localisedKey} key={error.customMessage} />
                ) : (
                  error.message
                )
              }
              image={error.image}
              onConfirm={() => dispatch(closeError(error))}
              onClose={() => dispatch(closeError(error))}
              button={<FormattedMessage id="btn.ok" />}
              color="primary"
            />
          )
      )}
      {errors.map(
        error =>
          error.show &&
          error.customError &&
          error.type &&
          ["error.claim.conflict.assigned_not_unassigned"].includes(error.type) && (
            <WarningAutoClose message={error.message} closeError={() => dispatch(closeError(error))} />
          )
      )}
      <WelcomeDialog isOpen={showingWelcomeMessage} onClose={() => dispatch(toggleWelcomeMessage(false))} />
      <NewConversationInInboxNotifier />
      {featureFlags.has("booking") && <BookingInboxScheduler />}
    </div>
  )
}

export default LoggedInLayout
