import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"

import { Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"

import { RootState } from "reducers/init"

// TODO: move to bones-ui?
export interface PatientListingData {
  id: string
  name: string
  address: string
  contactMyClinicEnabled: boolean
  contactClinicCategoryId: number
  distance: number
  listedAt: boolean
  order: number
}

const clinicAdapter = createEntityAdapter<Clinic>()

interface ClinicsState {
  clinics: EntityState<Clinic, string>
  patientClinics?: PatientListingData[]
}

const initialState: ClinicsState = {
  clinics: clinicAdapter.getInitialState(),
  patientClinics: undefined
}

const clinics = createSlice({
  name: "clinics",
  initialState,
  reducers: {
    loadClinics(state, action: PayloadAction<Clinic[]>) {
      clinicAdapter.setAll(state.clinics, action.payload)
    },
    loadPatientClinics(state, action: PayloadAction<[]>) {
      state.patientClinics = action.payload
    }
  }
})

export const { loadClinics, loadPatientClinics } = clinics.actions

export type ClinicsSliceAction = ObjectFunctionReturnTypes<typeof clinics.actions>

export const clinicSelectors = clinicAdapter.getSelectors<RootState>(state => state.clinics.clinics)

export default clinics.reducer
