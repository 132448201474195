import { useIntl } from "react-intl"

import PhrasesContainer from "@doktor-se/bones-ui/dist/web-shared/components/phrases/PhrasesContainer"

import { featureFlags, phrasesLanguages } from "config"
import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"

import PageContainer from "components/PageContainer/PageContainer"
import { feedTexts } from "pages/dashboard/utils"

import styles from "./PhrasesPage.module.scss"

const PhrasesPage = () => {
  const phraseData = useAppSelector(state => state.phrases.phrases)
  const user = useAppSelector(state => state.auth.user)
  const clinics = useAppSelector(clinicSelectors.selectAll)
  const intl = useIntl()
  const { phrases } = feedTexts(intl)

  const showFilterDropdown = featureFlags.has("phrases_filter_dropdown")
  const staffClinicId = !!user?.data?.clinic
  const staffClinic = clinics.find(c => c.id === user?.data?.clinic)?.name

  if (!phrasesLanguages || !phraseData) return null

  return (
    <PageContainer>
      <div className={styles.phrasesContainer}>
        <PhrasesContainer
          variableOpts={{
            staffFirstName: user?.firstname,
            staffLastName: user?.lastname,
            staffDisplayName: user?.displayName,
            title: user?.title,
            staffGender: user?.data?.sex,
            staffClinic
          }}
          dataList={phraseData}
          isPage={true}
          texts={phrases}
          emptySearchText={intl.formatMessage({ id: "phrases.search.empty" })}
          copyConfirmText={intl.formatMessage({ id: "copied.confirmation" })}
          phrasesLanguages={phrasesLanguages}
          showFilterDropdown={showFilterDropdown}
          initialFilterValue={staffClinicId ? "physical" : "digital"}
        />
      </div>
    </PageContainer>
  )
}

export default PhrasesPage
