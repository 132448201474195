import { useEffect, useMemo, useRef } from "react"
import { useIntl } from "react-intl"

import { StaffBodyTextXS } from "@doktor-se/bones-ui"
import { CallState } from "@doktor-se/bones-ui/dist/web-shared/types"

import { endCall, initializeTokbox, tearDownTokbox } from "api"
import ringtone from "audio/ringing.mp3"
import { featureFlags } from "config"
import { startDeepAR } from "lib/deepAR"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { conversationsSelectors } from "reducers/conversations/conversations.reducer"
import { CanvasElement } from "types"

import CallControls from "./components/CallControls/CallControls"
import PatientVideoContainer from "./components/PatientVideoContainer/PatientVideoContainer"
import BannerContainer from "components/banners/BannerContainer"

import styles from "./TokBox.module.scss"

const getDisplay = (callState: CallState, timer: string) => {
  switch (callState) {
    case "active":
      return timer
    case "outgoing":
      return "calls.calling"
    case "inactive":
      return "calls.ended"
    default:
      return ""
  }
}

const getReason = (callState: CallState) => {
  if (callState === "active") return "hangup"
  return "no_answer"
}

const TokBox = () => {
  const dispatch = useAppDispatch()
  const calls = useAppSelector(state => state.tokbox)
  const conversations = useAppSelector(conversationsSelectors.selectAll)
  const isVideoBackgroundActive = useAppSelector(state => state.app.isVideoBackgroundActive)
  const ringtoneVolume = useAppSelector(state => state.app.ringtoneVolume)
  const ringtoneAudio = useRef<HTMLAudioElement>(null)
  const conversation = useMemo(
    () => conversations.find(c => c.id === calls.conversationId),
    [calls.conversationId, conversations]
  )
  const intl = useIntl()

  useEffect(() => {
    let videoTracks: MediaStreamTrack[] = []
    let cleanupDeepAR: Function | undefined

    if (featureFlags.has("video_call_background") && isVideoBackgroundActive) {
      // create canvas on which DeepAR will render
      const deepARCanvas = document.createElement("canvas") as CanvasElement

      // Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
      // canvas.captureStream causes an error if getContext not called before. Chrome does not need the line below.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const canvasContext = deepARCanvas.getContext("webgl")

      const mediaStream = deepARCanvas.captureStream(25)
      videoTracks = mediaStream.getVideoTracks()
      cleanupDeepAR = startDeepAR(deepARCanvas)
    }

    dispatch(
      initializeTokbox(
        calls.connect!.sessionId,
        calls.connect!.token,
        conversations.find(c => c.id === calls.conversationId),
        calls.connect!.apiKey,
        videoTracks[0]
      )
    )

    return () => cleanupDeepAR?.()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ringtoneAudio.current) {
      if (calls.callState === "outgoing") {
        ringtoneAudio.current.play()
      } else {
        ringtoneAudio.current.pause()
      }
    }
  }, [calls.callState])

  useEffect(() => {
    if (ringtoneAudio.current) {
      ringtoneAudio.current.volume = ringtoneVolume
    }
  }, [ringtoneVolume])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(tearDownTokbox()), [])

  return (
    <>
      {conversation && (
        <div className={styles.container}>
          <audio ref={ringtoneAudio} loop src={ringtone}>
            <track kind="captions" />
          </audio>

          {calls.sessionReconnecting && (
            <BannerContainer className={styles.callWarningBanner}>
              <StaffBodyTextXS>{intl.formatMessage({ id: "calls.reconnecting" })}</StaffBodyTextXS>
            </BannerContainer>
          )}

          <PatientVideoContainer
            showVideo={calls.hasVideo.subscriber}
            conversation={conversation}
            displayedText={getDisplay(calls.callState, calls.timer.text)}
          />

          {["active", "outgoing"].includes(calls.callState) && (
            <CallControls endCall={() => dispatch(endCall(conversation, getReason(calls.callState)))} />
          )}
        </div>
      )}
    </>
  )
}

export default TokBox
