import { useIntl } from "react-intl"

import classNames from "classnames"

import { StaffBodyTextS, StaffHeading1, StaffHeading4 } from "@doktor-se/bones-ui"

import { helpPageLinks } from "config"
import { useAppSelector } from "lib/hooks"

import PageContainer from "components/PageContainer/PageContainer"

import styles from "./HelpPage.module.scss"

const HelpPage = () => {
  const { defaultRole } = useAppSelector(state => state.auth)
  const appLanguage = useAppSelector(state => state.app.language)
  const intl = useIntl()

  if (!defaultRole) return null

  const getLocalisedText = (texts: { [languageCode: string]: string | ((role: string) => string) }) =>
    texts[appLanguage] || texts["en"]

  return (
    <PageContainer>
      <StaffHeading1 margin={{ bottom: "30px" }}>{intl.formatMessage({ id: "label.help" })}</StaffHeading1>

      <div className={styles.links}>
        {helpPageLinks?.map((section, i) => {
          const sectionTitleText = getLocalisedText(section.title.text)

          var titleText = ""
          if (typeof sectionTitleText === "string") titleText = sectionTitleText
          else if (typeof sectionTitleText === "string")
            titleText = (sectionTitleText as (role: string) => string)(
              intl.formatMessage({ id: `label.role.${defaultRole}` })
            )

          return (
            <div key={i}>
              {(!section.title.showForRoles || section.title.showForRoles.includes(defaultRole)) && (
                <>
                  <div className={styles.title}>
                    <StaffHeading4 as="h2">{titleText}</StaffHeading4>
                    {section.title.link && (
                      <a
                        className={classNames(styles.link, styles.titleLink)}
                        href={section.title.link.href}
                        target="_blank"
                        rel="noopener noreferrer">
                        <StaffHeading4>{getLocalisedText(section.title.link.label) as string}</StaffHeading4>
                      </a>
                    )}
                  </div>

                  {section.title.subtitle && (
                    <StaffBodyTextS className={styles.subtitle}>
                      {getLocalisedText(section.title.subtitle) as string}
                    </StaffBodyTextS>
                  )}
                </>
              )}

              {section.links
                ?.filter(row => !row.showForRoles || row.showForRoles.includes(defaultRole))
                .map((row, i) => (
                  <div key={i} className={styles.linkContainer}>
                    {row.info?.placement === "before" && (
                      <StaffBodyTextS className={classNames(styles.info, styles.before)}>
                        {getLocalisedText(row.info.text) as string}
                      </StaffBodyTextS>
                    )}

                    {!!row.link && (
                      <a key={i} className={styles.link} href={row.link.href} target="_blank" rel="noopener noreferrer">
                        <StaffBodyTextS>{getLocalisedText(row.link.label) as string}</StaffBodyTextS>
                      </a>
                    )}

                    {row.info?.placement === "after" && (
                      <StaffBodyTextS className={classNames(styles.info, styles.after)}>
                        {getLocalisedText(row.info.text) as string}
                      </StaffBodyTextS>
                    )}
                  </div>
                ))}
            </div>
          )
        })}
      </div>
    </PageContainer>
  )
}

export default HelpPage
