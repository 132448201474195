import { useIntl } from "react-intl"

import { StaffBodyTextM, StaffHeading4 } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"

import CallBubbles from "../CallBubbles/CallBubbles"

import styles from "./AudioOnlyDisplay.module.scss"

export interface AudioOnlyDisplayProps {
  categoryId?: number
  displayName?: string
  displayedText: string
}

const AudioOnlyDisplay = ({ categoryId, displayName, displayedText }: AudioOnlyDisplayProps) => {
  const intl = useIntl()
  const category = useAppSelector(state => (categoryId ? categorySelectors.selectById(state, categoryId) : undefined))

  return (
    <div className={styles.container}>
      <CallBubbles symptom={category} />
      <StaffHeading4 margin={{ top: "-20px" }}>{displayName}</StaffHeading4>
      <StaffBodyTextM margin="10px 0 20px">
        {displayedText.includes(".") ? intl.formatMessage({ id: displayedText }) : displayedText}
      </StaffBodyTextM>
    </div>
  )
}

export default AudioOnlyDisplay
