import { useIntl } from "react-intl"

import classnames from "classnames"

import { ICONS, StaffLabelTextXS } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"
import { metersToKmString } from "lib/utils"

import styles from "./ListingInformation.module.scss"

const ListingInformation = () => {
  const intl = useIntl()
  const patientClinics = useAppSelector(state => state.clinics.patientClinics)

  if (!patientClinics || patientClinics.length === 0) {
    return null
  }

  // clinics are ordered by distance so we find closest (first)
  const closestClinic = patientClinics.find(c => c.order === 1)

  const isListed = closestClinic?.listedAt

  const listedText = isListed
    ? closestClinic.name
    : intl.formatMessage(
        { id: "label.patient.unlisted" },
        { clinic: `${closestClinic!.name} - ${metersToKmString(closestClinic!.distance)}` }
      )
  const IconComponent = isListed ? ICONS.Home : ICONS.Carecenter

  return (
    <div
      className={classnames(styles.listingContainer, {
        [styles.listingContainerListed]: isListed,
        [styles.listingContainerUnlisted]: !isListed
      })}>
      <IconComponent width={22} height={18} className={styles.icon} />
      <StaffLabelTextXS>{listedText}</StaffLabelTextXS>
    </div>
  )
}

export default ListingInformation
