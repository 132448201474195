import { useIntl } from "react-intl"

import classNames from "classnames"

import { StaffBodyTextXXS } from "@doktor-se/bones-ui"

import styles from "./CallControlButton.module.scss"

export interface CallControlButtonProps {
  onClick: () => void
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  variant: "default" | "selected" | "warning"
  label: string
  hasVisibleLabel?: boolean
}

const CallControlButton = ({ onClick, Icon, variant, label, hasVisibleLabel }: CallControlButtonProps) => {
  const intl = useIntl()

  return (
    <button
      aria-label={`${label.toLowerCase()} ${intl.formatMessage({ id: "app.call.control.button.aria.label" })}`}
      className={styles.button}
      onClick={onClick}>
      <div className={classNames(styles.iconContainer, styles[variant], { [styles.labelMargin]: hasVisibleLabel })}>
        <Icon />
      </div>

      {hasVisibleLabel && <StaffBodyTextXXS as="span">{label}</StaffBodyTextXXS>}
    </button>
  )
}

export default CallControlButton
