import { IntlShape, useIntl } from "react-intl"

import { StaffBodyTextXS } from "@doktor-se/bones-ui"

import BannerContainer from "./BannerContainer"

const close = ({ intl }: { intl: IntlShape }) => (
  <BannerContainer>
    <StaffBodyTextXS>{intl.formatMessage({ id: "warning.ws" })}</StaffBodyTextXS>
  </BannerContainer>
)

const reconnect = ({ intl, meta }: { intl: IntlShape; meta?: { retry?: number } }) => (
  <BannerContainer>
    <StaffBodyTextXS>{intl.formatMessage({ id: "warning.ws.attempt" }, { retry: meta && meta.retry })}</StaffBodyTextXS>
  </BannerContainer>
)

const stop = ({ intl, callback }: { intl: IntlShape; callback?: () => void }) => {
  if (!callback) return null
  return (
    <BannerContainer>
      <StaffBodyTextXS>{intl.formatMessage({ id: "warning.ws.failed" })}</StaffBodyTextXS>
      <button className="banner-action" onClick={() => callback()}>
        <StaffBodyTextXS>{intl.formatMessage({ id: "label.retry" })}</StaffBodyTextXS>
      </button>
    </BannerContainer>
  )
}

const message = {
  reconnect,
  stop,
  close
}

interface BannerProps {
  type: "reconnect" | "stop" | "close"
  meta?: { retry?: number }
  callback?: () => void
}

const Banner = ({ type, meta, callback }: BannerProps) => {
  const intl = useIntl()
  return message[type]({ intl, meta, callback })
}

export default Banner
