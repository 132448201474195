import { useState } from "react"
import { IntlShape, useIntl } from "react-intl"

import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"

import { Button, Dialog, StaffBodyTextM, StaffHeading4 } from "@doktor-se/bones-ui"

import TimeInput from "components/TimeInput/TimeInput"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import styles from "./SnoozeDialog.module.scss"

dayjs.extend(advancedFormat)

const getSnoozeList = (intl: IntlShape) => {
  return [
    {
      label: `30 ${intl.formatMessage({ id: "label.minutes" })}`,
      date: () => dayjs().add(30, "minutes").format(),
      display: () => dayjs().add(30, "minutes").format("HH:mm")
    },
    {
      label: `1 ${intl.formatMessage({ id: "label.hour" })}`,
      date: () => dayjs().add(1, "hours").format(),
      display: () => dayjs().add(1, "hours").format("HH:mm")
    },
    {
      label: `3 ${intl.formatMessage({ id: "label.hours" })}`,
      date: () => dayjs().add(3, "hours").format(),
      display: () => dayjs().add(3, "hours").format("HH:mm")
    }
  ]
}

export type SnoozeTime = { label: string; date: () => string; display: () => string }
interface SnoozeDialogProps {
  isOpen: boolean
  offset: Offset
  onSnooze: (snoozeTime: SnoozeTime) => void
  isBlocked?: boolean
  onClose: () => void
}

const SnoozeDialog = ({ isOpen, offset, onSnooze, isBlocked, onClose }: SnoozeDialogProps): JSX.Element => {
  const intl = useIntl()

  const [time, setTime] = useState("")
  const [custom, setCustom] = useState({
    label: intl.formatMessage({ id: "label.custom" }),
    date: () => "" as string,
    display: () => "" as string
  })

  const snoozeList = getSnoozeList(intl)

  const setCustomTime = (customTime: string) => {
    setTime(customTime)
    let day = dayjs().format("YYYY-MM-DD")
    const currentTime = dayjs().format("HH:mm")
    if (currentTime >= customTime) day = dayjs().add(1, "days").format("YYYY-MM-DD")

    if (customTime) {
      setCustom({
        ...custom,
        date: () => dayjs(`${day} ${customTime}`).format(),
        display: () => dayjs(`${day} ${customTime}`).format("Do MMM HH:mm")
      })
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={{ top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onClose}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}>
      <div className={styles.container}>
        {isBlocked && <StaffHeading4>{intl.formatMessage({ id: "control.snooze.blocked" })}</StaffHeading4>}

        {!isBlocked && (
          <>
            {snoozeList.map((s, index) => (
              <button className={styles.row} key={index} onClick={() => onSnooze(s)}>
                <StaffHeading4>{s.label}</StaffHeading4>
                <StaffBodyTextM>{s.display()}</StaffBodyTextM>
              </button>
            ))}

            <StaffHeading4 className={styles.customText}>{intl.formatMessage({ id: "label.custom" })}</StaffHeading4>

            <div className={styles.customTimeWrapper}>
              <TimeInput value={time} onChange={setCustomTime} className={styles.customTimeInput} />
              <Button variant="primary" color="primary" isDisabled={!time} onPress={() => time && onSnooze(custom)}>
                {intl.formatMessage({ id: "btn.confirm" })}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default SnoozeDialog
