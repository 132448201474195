import { useIntl } from "react-intl"

import { Button, Dialog, StaffBodyTextM } from "@doktor-se/bones-ui"

import { IconCameraInfo, IconMicrophoneInfo } from "icons"

import styles from "./WelcomeDialog.module.scss"

interface WelcomeDialogProps {
  isOpen: boolean
  onClose: () => void
}
const WelcomeDialog = ({ isOpen, onClose }: WelcomeDialogProps) => {
  const intl = useIntl()

  return (
    <Dialog isOpen={isOpen} onClose={onClose} closeAriaLabel={intl.formatMessage({ id: "modal.close" })} fixedWidth>
      {
        <>
          <div className={styles.iconContainer}>
            <IconMicrophoneInfo />
            <IconCameraInfo />
          </div>

          <StaffBodyTextM margin="var(--size-200) 0">
            {intl.formatMessage({ id: "info.login.settings" })}
          </StaffBodyTextM>

          <Button onPress={onClose} variant="primary" color="primary">
            {intl.formatMessage({ id: "btn.understand" })}
          </Button>
        </>
      }
    </Dialog>
  )
}

export default WelcomeDialog
