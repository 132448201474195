import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import {
  Button,
  CopyToClipboard,
  ICONS,
  StaffBodyTextXS,
  StaffBodyTextXXS,
  StaffHeading4,
  StaffHeading5
} from "@doktor-se/bones-ui"
import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { useInputHeight } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import {
  Category,
  ConversationHistory,
  JournalNote,
  JournalSection,
  Staff
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { isEqual } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { saveJournalNote } from "api"
import { appStatic } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { updateLocalConversationState } from "reducers/selected"

import { fetchLanguage, getSupportedLanguage, Translations } from "components/Localization/Localization"

import styles from "./Journal.module.scss"

export interface JournalProps {
  selectedJournal?: ConversationHistory
}

const Journal = ({ selectedJournal }: JournalProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const conversation = useAppSelector(selectConversation)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const localConversationStates = useAppSelector(state => state.selected.localConversationStates)
  const staff = useAppSelector(state => state.users.staff)
  const [translations, setTranslations] = useState<Translations>({} as Translations)

  useEffect(() => {
    fetchLanguage(getSupportedLanguage(selectedJournal?.metadata?.language)).then(translations => {
      setTranslations(translations.default)
    })
  }, [selectedJournal])

  const statusRef = useRef(null)
  const anamnesisRef = useRef(null)
  const recommendationRef = useRef(null)

  const editable: boolean = useMemo(
    () => (selectedJournal && conversation?.id === selectedJournal.id) || false,
    [conversation, selectedJournal]
  )

  const languageTitles = useMemo(
    () => ({
      anamnesis: translations["journal.anamnesis"],
      status: translations["journal.status"],
      recommendation: translations["journal.recommendation"]
    }),
    [translations]
  )

  const category: Category | undefined = useMemo(
    () => selectedJournal && categories[selectedJournal.categoryId],
    [categories, selectedJournal]
  )

  const writtenBy: Staff | undefined = useMemo(
    () =>
      (selectedJournal &&
        selectedJournal.journalNote &&
        selectedJournal.journalNote.createdById &&
        staff.find(s => s.id === selectedJournal!.journalNote!.createdById)) ||
      undefined,
    [selectedJournal, staff]
  )

  const draft: JournalNote | undefined = useMemo(() => {
    if (selectedJournal && localConversationStates) {
      const found = localConversationStates.find(d => d.conversationId === selectedJournal.id)
      if (found) return found.state.journalDraft
    }
  }, [localConversationStates, selectedJournal])

  const newChanges: boolean | undefined = useMemo(() => {
    if (!draft || !selectedJournal) return false

    if (!selectedJournal.journalNote) return !draft.data.sections.every(section => !section.body)

    if (!draft.data.sections.length) return true

    return !draft.data.sections.every((section, i) => {
      return isEqual(section, selectedJournal.journalNote!.data.sections[i])
    })
  }, [selectedJournal, draft])

  const sections = useMemo(() => {
    if (draft) {
      const sections = draft.data.sections
      const status = sections.find(d => d.title === languageTitles.status)
      const anamnesis = sections.find(d => d.title === languageTitles.anamnesis)
      const recommendation = sections.find(d => d.title === languageTitles.recommendation)
      return {
        status: status?.body,
        anamnesis: anamnesis?.body,
        recommendation: recommendation?.body
      }
    }
    return {}
  }, [languageTitles, draft])

  const buttonState: { text: string; Icon?: JSX.Element; disabled: boolean } = useMemo(() => {
    if (!!selectedJournal?.journalNote && !newChanges) {
      return { text: intl.formatMessage({ id: "journal.changes.none" }), Icon: <ICONS.Check />, disabled: true }
    }

    return {
      text: intl.formatMessage({ id: "journal.btn.send" }),
      disabled: !newChanges || !draft?.data.sections.length
    }
  }, [draft?.data.sections.length, intl, newChanges, selectedJournal?.journalNote])

  useInputHeight(statusRef, sections.status || "")
  useInputHeight(anamnesisRef, sections.anamnesis || "")
  useInputHeight(recommendationRef, sections.recommendation || "")

  const updateDraft = (title: string, body: string) => {
    const trimmedBody = body.trim()
    if (draft) {
      const updateSections = [...draft.data.sections.filter(d => d.title !== title)]
      if (trimmedBody) updateSections.push({ title, body })
      const updatedDraft = {
        ...draft,
        data: {
          ...draft.data,
          sections: updateSections
        }
      }
      conversation &&
        dispatch(
          updateLocalConversationState({ conversationId: conversation.id, state: { journalDraft: updatedDraft } })
        )
    } else {
      if (trimmedBody)
        conversation &&
          dispatch(
            updateLocalConversationState({
              conversationId: conversation.id,
              state: {
                journalDraft: {
                  conversationId: selectedJournal!.id,
                  data: { sections: [{ title, body }] },
                  type: "journal_note"
                }
              }
            })
          )
    }
  }

  const sortSections = (sections: JournalSection[]) => {
    const order = [languageTitles.anamnesis, languageTitles.status, languageTitles.recommendation]
    return [...sections].sort((a: JournalSection, b: JournalSection) => order.indexOf(a.title) - order.indexOf(b.title))
  }

  const sendToUser = () => {
    if (draft) dispatch(saveJournalNote(selectedJournal!.id, sortSections(draft.data.sections)))
  }

  return (
    <div className={styles.container}>
      {selectedJournal && languageTitles && (
        <>
          <div className={styles.heading}>
            <StaffHeading4 as="h2">{intl.formatMessage({ id: "journal.title" })}</StaffHeading4>

            <ICONS.Close
              className={styles.icon}
              onClick={() =>
                conversation &&
                dispatch(
                  updateLocalConversationState({
                    conversationId: conversation.id,
                    state: { selectedJournal: undefined }
                  })
                )
              }
            />
          </div>

          <div className={styles.body}>
            <div className={styles.info}>
              <div className={styles.infoContainer}>
                <SymptomBubble symptom={category} appStatic={appStatic} />
                {selectedJournal.journalNote && (
                  <div className={styles.infoText}>
                    <StaffBodyTextXS className={styles.name}>{writtenBy && writtenBy.displayName}</StaffBodyTextXS>
                    <StaffBodyTextXXS className={styles.created}>
                      {`${intl.formatDate(selectedJournal.journalNote.created, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                      })} ${intl.formatTime(selectedJournal.journalNote.created)}`}
                    </StaffBodyTextXXS>
                  </div>
                )}
              </div>
              {selectedJournal.journalNote && (
                <CopyToClipboard
                  copyText={`${languageTitles.anamnesis}\n${sections.anamnesis}\n\n${languageTitles.status}\n${sections.status}\n\n${languageTitles.recommendation}\n${sections.recommendation}`}
                  confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
                />
              )}
            </div>

            <div className={classNames({ [styles.editable]: editable })}>
              <div className={styles.section}>
                <StaffHeading5 as="h3" margin={{ bottom: "5px" }} className={styles.sectionHeader}>
                  {languageTitles.anamnesis}
                </StaffHeading5>

                <textarea
                  className={styles.sectionInput}
                  value={sections.anamnesis || ""}
                  onChange={evt => updateDraft(languageTitles.anamnesis, evt.target.value)}
                  ref={anamnesisRef}
                  disabled={!editable}
                />
              </div>

              <div className={styles.section}>
                <StaffHeading5 as="h3" margin={{ bottom: "5px" }} className={styles.sectionHeader}>
                  {languageTitles.status}
                </StaffHeading5>

                <textarea
                  className={styles.sectionInput}
                  value={sections.status || ""}
                  onChange={evt => updateDraft(languageTitles.status, evt.target.value)}
                  ref={statusRef}
                  disabled={!editable}
                />
              </div>

              <div className={styles.section}>
                <StaffHeading5 as="h3" margin={{ bottom: "5px" }} className={styles.sectionHeader}>
                  {languageTitles.recommendation}
                </StaffHeading5>

                <textarea
                  className={styles.sectionInput}
                  value={sections.recommendation || ""}
                  onChange={evt => updateDraft(languageTitles.recommendation, evt.target.value)}
                  ref={recommendationRef}
                  disabled={!editable}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {editable && (
                <Button
                  variant="primary"
                  color="primary"
                  onPress={sendToUser}
                  isDisabled={buttonState.disabled}
                  Icon={buttonState.Icon}>
                  {buttonState.text}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Journal
