import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"

import { Dialog, GlobalSnackbarRegion } from "@doktor-se/bones-ui"
import "@doktor-se/bones-ui/dist/fonts/fonts.css"
import { Theme } from "@doktor-se/bones-ui/dist/web-shared/components"
import { MsalEntryProvider } from "@doktor-se/bones-ui/dist/web-shared/config"
import { datadogInit, ErrorBoundary } from "@doktor-se/more-rum-please"

import { datadogConfig, themeName } from "config"
import { amplitudeInit } from "lib/amplitude/amplitude"
import { datadogLogsInit } from "lib/datadog"

import App from "./components/App"
import Redux from "components/redux/Redux"

import "./styles/main.scss"

datadogInit(datadogConfig)
datadogLogsInit(datadogConfig)
amplitudeInit()

const container = document.getElementById("root")
const root = createRoot(container!)
const queryClient = new QueryClient()

root.render(
  <ErrorBoundary>
    <Redux>
      <Theme name={themeName}>
        <MsalEntryProvider
          app="carealot"
          appApi={import.meta.env.VITE_APP_APP_API}
          serviceZone={import.meta.env.VITE_APP_SERVICE_ZONE}
          serviceZoneAlt={import.meta.env.VITE_APP_SERVICE_ZONE_ALT}>
          <BrowserRouter basename={import.meta.env.BASE_URL}>
            <QueryClientProvider client={queryClient}>
              <Dialog.ModalProvider>
                <GlobalSnackbarRegion />
                <App queryClient={queryClient} />
              </Dialog.ModalProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </MsalEntryProvider>
      </Theme>
    </Redux>
  </ErrorBoundary>
)
