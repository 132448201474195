import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextS, StaffHeading2 } from "@doktor-se/bones-ui"
import { Texts } from "@doktor-se/bones-ui/dist/web-shared/components/feed/types"
import { AssignedConversation, Category, Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"
import { ageFromDateWithMonths, formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { setMetadataOnConversation } from "api"
import { featureFlags } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { getConversationClinic } from "lib/utils"
import { clinicSelectors } from "reducers/clinics"

import CategoriesModal from "components/CategoriesModal/CategoriesModal"
import CategoryBadge from "components/CategoryBadge/CategoryBadge"
import CountryFlag from "components/CountryFlag/CountryFlag"
import PatientAvatar from "components/PatientAvatar/PatientAvatar"
import { ButtonEditCategory } from "icons"
import SelectClinic from "pages/dashboard/components/ConversationControls/components/FeedHeader/SelectClinic/SelectClinic"

import ListingInformation from "./ListingInformation/ListingInformation"

import styles from "./FeedHeader.module.scss"

interface FeedHeaderProps {
  conversation: AssignedConversation
  categories: Category[]
  updateCategory: (categoryId: Category["id"]) => void
  texts: Texts
}

const FeedHeader = ({ conversation, categories, updateCategory, texts }: FeedHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [showModal, setShowModal] = useState(false)
  const { profile, categoryId, id, account, metadata } = conversation
  const clinics = useAppSelector(clinicSelectors.selectAll)
  const conversationClinic: Clinic | undefined = useMemo(() => {
    return getConversationClinic({
      clinics,
      metadata: { platform: conversation.metadata, customer: conversation.customerMetadata }
    })
  }, [clinics, conversation.customerMetadata, conversation.metadata])

  const displayAge = useMemo(() => {
    if (profile) {
      const [months, years] = ageFromDateWithMonths(profile.data.dateOfBirth)
      if (typeof years === "number" && years < 1) {
        if (typeof months === "number" && months === 1) return intl.formatMessage({ id: "age.under.one.year.singular" })
        if (typeof months === "number" && months < 1) return intl.formatMessage({ id: "age.under.one.month" })
        return intl.formatMessage({ id: "age.under.one.year.plural" }, { age: months })
      }
      if (typeof years === "number" && years === 1) return intl.formatMessage({ id: "age.one.year" })
      if (years) return intl.formatMessage({ id: "age.over.one.year" }, { age: years })
    }
    return ""
  }, [intl, profile])

  const category = useMemo(() => categories.find(category => category.id === categoryId), [categories, categoryId])

  const isPremiumHealth = useMemo(() => {
    return category?.metadata.conversationMetaInit?.premiumHealth
  }, [category])

  const isRevisit = useMemo(() => {
    return category?.metadata.conversationMetaInit?.revisit
  }, [category])

  const categoryBadgeType = useMemo(() => {
    if (isPremiumHealth) return "premiumHealth"
    if (isRevisit) return "revisit"
    return "general"
  }, [isPremiumHealth, isRevisit])

  return (
    <div className={styles.container}>
      {id && account && (
        <div className={styles.content}>
          <div className={styles.bubbleContainer}>
            {featureFlags.has("patient_avatar") && (
              <PatientAvatar
                size="big"
                letter={account?.displayName?.charAt(0)}
                imgName={featureFlags.has("profile_icon") ? profile?.data.icon : undefined}
              />
            )}
            <button
              data-testid="category-button"
              className={styles.categoryButton}
              onClick={() => setShowModal(!showModal)}>
              <CategoryBadge size="big" category={category} type={categoryBadgeType} />
              <ButtonEditCategory className={styles.penIcon} />
            </button>
          </div>

          <div>
            {featureFlags.has("patient_info") && (
              <>
                <div className={styles.nameContainer}>
                  {profile && <ICONS.Child className={styles.childIcon} />}
                  <StaffHeading2 as="p" className={styles.name}>
                    {profile ? profile.name : account.displayName}
                  </StaffHeading2>
                  {featureFlags.has("region") && metadata?.region && (
                    <CountryFlag
                      className={styles.regionFlag}
                      countryOrLanguageCode={metadata.region}
                      height={20}
                      width={20}
                    />
                  )}
                </div>

                <StaffBodyTextS className={styles.detailsText}>
                  {!featureFlags.has("hide_pnr_in_header")
                    ? profile
                      ? formatPnr(profile.data.securityNumber, account.region)
                      : formatPnr(account?.pnr, account.region)
                    : ""}
                </StaffBodyTextS>
                {featureFlags.has("physical_to_digital") && !featureFlags.has("clinic_tag") && <ListingInformation />}
                {!featureFlags.has("physical_to_digital") && featureFlags.has("clinic_tag") && clinics && (
                  <SelectClinic
                    clinics={clinics}
                    setClinic={clinicId => dispatch(setMetadataOnConversation(conversation.id, { clinic: clinicId }))}
                    listedClinicId={conversation.customerMetadata?.listedAtClinicId}
                    selectedClinic={conversationClinic}
                    clinicTagTexts={texts.clinicTag}
                  />
                )}
              </>
            )}

            {featureFlags.has("pet_info") && (
              <>
                <div className={styles.nameContainer}>
                  <StaffHeading2 className={styles.name}>{profile?.name}</StaffHeading2>
                  {featureFlags.has("region") && metadata?.region && (
                    <CountryFlag
                      className={styles.regionFlag}
                      countryOrLanguageCode={metadata.region}
                      height={20}
                      width={20}
                    />
                  )}
                </div>
                <div className={styles.detailsContainer}>
                  <StaffBodyTextS className={styles.detailsText}>{displayAge}</StaffBodyTextS>
                  <StaffBodyTextS className={styles.detailsText}>
                    {`, ${intl.formatMessage({ id: `sex.${profile?.data.sex}` })}`}
                  </StaffBodyTextS>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <CategoriesModal
        selectedCategory={category}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        updateCategory={updateCategory}
      />
    </div>
  )
}

export default FeedHeader
