import { useIntl } from "react-intl"

import { CopyToClipboard, StaffBodyTextS } from "@doktor-se/bones-ui"

import styles from "./PatientInfoRow.module.scss"

interface PatientInfoRowProps {
  label: string
  data?: string
  copyData?: string
  hideCopyToClippoard?: boolean
  icon?: React.ReactNode
}

export const PatientInfoRow = ({ label, data, copyData, hideCopyToClippoard = false, icon }: PatientInfoRowProps) => {
  const intl = useIntl()
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <StaffBodyTextS className={styles.heading} margin={{ bottom: "4px" }}>
          {label}
        </StaffBodyTextS>
        <div className={styles.dataWrapper}>
          {icon && icon}
          <StaffBodyTextS className={styles.data}>{data || "-"}</StaffBodyTextS>
        </div>
      </div>

      <div className={styles.copy}>
        {copyData && (
          <CopyToClipboard copyText={copyData} confirmationText={intl.formatMessage({ id: "copied.confirmation" })} />
        )}

        {!copyData && typeof data === "string" && !hideCopyToClippoard && (
          <CopyToClipboard copyText={data} confirmationText={intl.formatMessage({ id: "copied.confirmation" })} />
        )}
      </div>
    </div>
  )
}
export default PatientInfoRow
