import { SelectedState, Timeouts } from "."

import {
  AssignedConversation,
  ConversationHistory,
  JournalNote,
  LocalConversationState
} from "@doktor-se/bones-ui/dist/web-shared/types"

export const newTimeout = (
  type: "snooze" | "postpone" | "booking",
  oldTimeouts: Timeouts,
  {
    data,
    timeout
  }: {
    timeout: ReturnType<typeof setTimeout>
    data: { conversationId: string; postponeUntil?: string }
  }
): { timeouts: Timeouts } => {
  const timeoutsByType = Array.isArray(oldTimeouts[type])
    ? oldTimeouts[type].filter(t => {
        if (t.id === data.conversationId) clearTimeout(t.timeout)
        return t.id !== data.conversationId
      })
    : []
  return {
    timeouts: {
      ...oldTimeouts,
      [type]: [
        ...timeoutsByType,
        {
          id: data.conversationId,
          timeout
        }
      ]
    }
  }
}

const mergeJournalDrafts = (state: SelectedState, journalDrafts: JournalNote[]): LocalConversationState[] => {
  const localConversationStates = state.localConversationStates || []
  const draftIds = localConversationStates.map(s => s.conversationId)
  const inState = journalDrafts.filter(j => draftIds.includes(j.conversationId!))
  const notInState = journalDrafts.filter(j => !draftIds.includes(j.conversationId!))

  return localConversationStates
    .map(s => {
      const match = inState.find(j => j.conversationId === s.conversationId)
      if (match && ((s.state.journalDraft && match.id !== s.state.journalDraft.id) || !s.state.journalDraft))
        return { ...s, state: { ...s.state, journalDraft: match } }
      return s
    })
    .concat(notInState.map(j => ({ conversationId: j.conversationId!, state: { journalDraft: j } })))
}

const reduceJournalDrafts = (history: ConversationHistory[]): JournalNote[] =>
  history.reduce((acc: JournalNote[], h: ConversationHistory) => {
    if (h.journalNote)
      acc.push({
        ...h.journalNote,
        conversationId: h.id
      })
    return acc
  }, [] as JournalNote[])

const filterUniqueJournalDrafts = (journalDrafts: JournalNote[]): JournalNote[] =>
  journalDrafts.filter(
    (draft: JournalNote, i: number, array: JournalNote[]) =>
      array.findIndex(d => d.conversationId === draft.conversationId) === i
  )

export const setJournalDraft = (state: SelectedState, conversationHistory?: ConversationHistory[]): SelectedState => {
  if (!conversationHistory) return state

  const localConversationStates: LocalConversationState[] = mergeJournalDrafts(
    state,
    filterUniqueJournalDrafts(reduceJournalDrafts(conversationHistory))
  )
  return { ...state, localConversationStates }
}

export const setJournalDraftWebdoc = (state: SelectedState, journalNote: JournalNote): SelectedState => {
  const currentConverationStates = state.localConversationStates?.filter(
    ls => ls.conversationId !== state.conversationId
  )
  const localConversationStates: LocalConversationState[] = [
    ...(currentConverationStates ? currentConverationStates : []),
    {
      conversationId: state.conversationId!,
      state: { journalDraft: journalNote }
    }
  ]
  return { ...state, localConversationStates }
}
export const reduceConversationHistory = (conversations: AssignedConversation[]): ConversationHistory[] =>
  conversations.reduce((acc: ConversationHistory[], c: AssignedConversation) => {
    if (c.history) {
      return acc.concat(c.history)
    }
    return acc
  }, [] as ConversationHistory[])

export const updateLocalState = (
  state: SelectedState,
  conversationId: string,
  newState: {
    commentDraft?: string
    messageDraft?: string
    commentState?: boolean
    journalDraft?: JournalNote
    selectedJournal?: ConversationHistory
  }
): SelectedState => {
  const localConversationStates = state.localConversationStates || []
  const localState = localConversationStates.find(s => s.conversationId === conversationId)

  if (localState)
    return {
      ...state,
      localConversationStates: [
        ...localConversationStates.filter(s => s.conversationId !== conversationId),
        { conversationId, state: { ...localState.state, ...newState } }
      ]
    }
  return {
    ...state,
    localConversationStates: [...localConversationStates, { conversationId, state: newState }]
  }
}
