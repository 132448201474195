import { useIntl } from "react-intl"

import { StaffBodyTextXS, StaffBodyTextXXS } from "@doktor-se/bones-ui"
import { Category, ConversationHistory } from "@doktor-se/bones-ui/dist/web-shared/types"

import HistoryJournalRow from "./HistoryJournalRow"

import styles from "./HistoryJournal.module.scss"

interface HistoryJournalProps {
  history?: ConversationHistory[]
  categories: Record<number, Category>
  openJournal: (selectedJournal: ConversationHistory) => void
  selectedJournalId?: string
  activeConversation?: ConversationHistory
}

const HistoryJournal = ({
  history,
  categories,
  openJournal,
  selectedJournalId,
  activeConversation
}: HistoryJournalProps): JSX.Element => {
  const intl = useIntl()

  return (
    <div className={styles.history}>
      <StaffBodyTextXS margin={{ bottom: "10px" }} className={styles.header}>
        {intl.formatMessage({ id: "patient.info.current.conversation" })}
      </StaffBodyTextXS>
      <div className={styles.body}>
        {activeConversation && (
          <HistoryJournalRow
            created={activeConversation.created}
            active={activeConversation.id === selectedJournalId}
            openJournal={() => openJournal(activeConversation)}
            category={categories[activeConversation.categoryId]}
          />
        )}
      </div>

      <StaffBodyTextXS margin={{ bottom: "10px" }} className={styles.header}>
        {intl.formatMessage({ id: "patient.info.history" })}
      </StaffBodyTextXS>
      <ul className={styles.body}>
        {history && history.length > 0 ? (
          history.map(conv => (
            <HistoryJournalRow
              key={conv.id}
              created={conv.created}
              active={conv.id === selectedJournalId}
              openJournal={() => openJournal(conv)}
              category={categories[conv.categoryId]}
            />
          ))
        ) : (
          <li>
            <StaffBodyTextXXS>–</StaffBodyTextXXS>
          </li>
        )}
      </ul>
    </div>
  )
}
export default HistoryJournal
