import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AnyFilteredMessage, AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { conversationLimit, featureFlags } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import {
  checkOrStoreSessionInLocalStorage,
  decryptMessages,
  hasAccessToEncryptedSession,
  staffIsOnConsentGivenList
} from "lib/seald"
import { Booking, personalBookingsSelectors } from "reducers/booking"
import { selectConversation, selectConversations } from "reducers/conversations/conversations.reducer"
import { queueSelectors } from "reducers/queue/queue.reducer"
import { setConversation } from "reducers/selected"

import ConversationControls from "./components/ConversationControls/ConversationControls"
import DeviceSwitchDialog from "./components/encryption/DeviceSwitchDialog/DeviceSwitchDialog"
import SealdRevokedDialog from "./components/encryption/SealdRevokedDialog/SealdRevokedDialog"
import {
  ConversationContainer,
  EncryptedConversationContainer
} from "components/ConversationContainer/ConversationContainer"
import Inbox from "components/Inbox/Inbox"
import PageContainer from "components/PageContainer/PageContainer"
import QueuesContainer from "components/Queues/QueuesContainer"

import { fetchAssignedConversation, fetchPatientsClinics } from "../../api"
import { feedTexts } from "./utils"

import styles from "./DashboardPage.module.scss"

export const sortWaiting = (list: AssignedConversation[], bookings?: Booking[]) =>
  list.sort((a, b) => {
    const bookingA = a.metadata?.bookingId ? bookings?.find(booking => booking.id === a.metadata?.bookingId) : undefined
    const bookingB = b.metadata?.bookingId ? bookings?.find(booking => booking.id === b.metadata?.bookingId) : undefined

    const valueA = a.snoozedUntil || a.postponedUntil || bookingA?.start
    const valueB = b.snoozedUntil || b.postponedUntil || bookingB?.start
    return dayjs(valueA).diff(dayjs(valueB))
  })

const DashboardPage = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const conversation = useAppSelector(selectConversation)
  const bookings = useAppSelector(personalBookingsSelectors.selectAll).filter(b => b.status === "booked")
  const { active, waiting } = useAppSelector(selectConversations)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const showQueue = useAppSelector(state => state.auth.cherryPick) || featureFlags.has("cherrypick")
  const activeInboxTab = useAppSelector(state => state.app.activeInboxTab)
  const queues = useAppSelector(queueSelectors.selectAll)
  const appLanguage = useAppSelector(state => state.app.language)
  const prevConversationId = usePrevious(conversation?.id) as string
  const prevMessages = usePrevious(conversation?.messagesToDisplay)
  const [searchString, setSearchString] = useState("")
  const sealdSDKInitiated = useAppSelector(state => state.encryption.sealdSDKInitialisationStatus)
  const user = useAppSelector(state => state.auth.user)

  const texts = feedTexts(intl, queues, appLanguage)
  const [messagesToDisplayFiltered, setMessagesToDisplayFiltered] = useState([
    ...(conversation?.messagesToDisplay || [])
  ])
  /*  
  Conversation change gets passed on to child components in a render before filteredMessages is updated.
  This caused unwanted calls to fetchSignedUrl on unmoutning the Event component.
  conversationIdForFilteredMessages will instead be set after filteredMessages and passed to the Event component.
  */
  const [conversationIdForFilteredMessages, setConversationIdForFilteredMessages] = useState(conversation?.id)

  const sealdEncryptionFeatureEnabled = useAppSelector(state => state.encryption.encryptionEnabled)
  const [encryptionSessionState, setEncryptionSessionState] = useState<"pending" | "success" | "error">("pending")

  const ConversationContainerComponent = sealdEncryptionFeatureEnabled
    ? EncryptedConversationContainer
    : ConversationContainer

  const { conversations, conversationLimitReached } = useMemo(() => {
    const limitForRole = conversationLimit && conversationLimit.find(limit => limit.role === defaultRole)
    return {
      conversations: activeInboxTab === "inbox" ? active : sortWaiting(waiting, bookings),
      conversationLimitReached: !!limitForRole && active.length >= limitForRole.amount
    }
  }, [activeInboxTab, active, waiting, defaultRole, bookings])

  useEffect(() => {
    if (featureFlags.has("conversations_load_split") && conversation?.id && !conversation?.messages) {
      dispatch(fetchAssignedConversation(conversation.id))
    }
    if (featureFlags.has("to_queue_select_clinic") && conversation?.id && conversation?.patientId) {
      dispatch(fetchPatientsClinics(conversation?.patientId))
    }
    if (conversation?.messagesToDisplay === prevMessages) return

    let newMessages = conversation?.messagesToDisplay || []

    if (searchString !== "" && conversation?.id !== prevConversationId) {
      setSearchString("")
    }

    if (searchString !== "" && conversation?.id === prevConversationId) {
      newMessages = !!conversation.messagesToDisplay
        ? [...messagesToDisplayFiltered, conversation.messagesToDisplay[conversation.messagesToDisplay.length - 1]]
        : [...messagesToDisplayFiltered]
    }

    if (sealdEncryptionFeatureEnabled) {
      setEncryptionSessionState("pending")
      const checkAccessToSession = async () => {
        const checkResult = user && checkOrStoreSessionInLocalStorage(conversation!, user)
        if (checkResult === "error") return setEncryptionSessionState("error")
        if (checkResult === "nothingToStore") return setEncryptionSessionState("success")

        const access = await hasAccessToEncryptedSession(conversation!.customerMetadata?.encryption?.currentSessionId!)
        if (access && staffIsOnConsentGivenList(user, conversation!)) {
          const decrypt = async (messages: AnyFilteredMessage[]) => {
            newMessages = (await decryptMessages(messages, conversation!.id, dispatch)) || []
            setMessagesToDisplayFiltered(newMessages)
          }

          decrypt(newMessages)
        } else {
          setMessagesToDisplayFiltered([])
        }
        setEncryptionSessionState(access ? "success" : "error")
      }

      // if there is no encryption this means we are awaiting consent
      if (conversation && conversation.customerMetadata?.encryption) {
        checkAccessToSession()
      } else {
        setEncryptionSessionState("success")
      }
    } else {
      setMessagesToDisplayFiltered(newMessages)
    }

    setConversationIdForFilteredMessages(conversation?.id)
  }, [
    conversation,
    messagesToDisplayFiltered,
    searchString,
    setMessagesToDisplayFiltered,
    prevMessages,
    prevConversationId,
    setConversationIdForFilteredMessages,
    sealdEncryptionFeatureEnabled,
    sealdSDKInitiated,
    dispatch,
    user
  ])

  const hasSelectedConversation = !!conversation && conversations.some(e => e.id === conversation.id)
  return (
    <PageContainer className={styles.container}>
      {sealdEncryptionFeatureEnabled && (
        <>
          <DeviceSwitchDialog />
          <SealdRevokedDialog />
        </>
      )}
      <Inbox
        selectedConversationId={conversation && conversation.id}
        assignedConversations={conversations}
        setConversation={c => dispatch(setConversation(c.id))}
        assignToUserBlocked={conversationLimitReached}
      />

      <div className={styles.contentContainer}>
        {hasSelectedConversation && (
          <ConversationControls conversations={conversations} conversation={conversation} texts={texts} />
        )}

        <div className={styles.bodyContainer}>
          <div className={styles.conversationContainer}>
            {hasSelectedConversation && conversationIdForFilteredMessages && (
              <ConversationContainerComponent
                conversation={conversation}
                hasQueuePanel={showQueue}
                messagesToDisplayFiltered={messagesToDisplayFiltered}
                conversationIdForFilteredMessages={conversationIdForFilteredMessages}
                texts={texts}
                sessionState={encryptionSessionState}
              />
            )}
          </div>

          {showQueue && <QueuesContainer assignToUserBlocked={conversationLimitReached} />}
        </div>
      </div>
    </PageContainer>
  )
}

export default DashboardPage
