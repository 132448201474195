import { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import classNames from "classnames"
import dayjs from "dayjs"
import calendar from "dayjs/plugin/calendar"

import { ICONS, StaffBodyTextS, StaffBodyTextXS, StaffHeading5, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { useTimeToNow } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { countyCodeNames } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { claimConversation } from "api"
import { appStatic, featureFlags, queueTimeClass } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { clinicSelectors } from "reducers/clinics"
import { QueueConversation } from "types"

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import CountryFlag from "components/CountryFlag/CountryFlag"

import styles from "./QueueConversationBox.module.scss"

dayjs.extend(calendar)

interface QueueConversationBoxProps {
  conversation: QueueConversation
  queueName?: string
  assignToUserBlocked: boolean
  postponed: boolean
  noBorder?: boolean
}

const QueueConversationBox = ({
  conversation,
  queueName,
  assignToUserBlocked,
  postponed,
  noBorder
}: QueueConversationBoxProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const clinics = useAppSelector(clinicSelectors.selectEntities)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const time = useTimeToNow(conversation.queuePriority)
  const [showModal, setShowModal] = useState(false)
  const clinic = useMemo(() => {
    if (conversation.metadata?.clinic) {
      return clinics[conversation.metadata.clinic]
    }
    if (conversation.customerMetadata?.listedAtClinicId) {
      return clinics[conversation.customerMetadata.listedAtClinicId]
    }
  }, [clinics, conversation.customerMetadata, conversation.metadata])
  const profile = conversation?.profile

  const category = useMemo(
    () => (conversation.categoryId ? categories[conversation.categoryId] : undefined),
    [categories, conversation.categoryId]
  )

  const postponedUntil = useMemo(() => {
    if (conversation.postponedUntil) {
      return dayjs(conversation.postponedUntil).calendar(undefined, {
        sameDay: `HH:mm [${intl.formatMessage({ id: "unassign.postpone.today" })}]`,
        nextDay: `HH:mm [${intl.formatMessage({ id: "unassign.postpone.tomorrow" })}]`
      })
    }
  }, [conversation.postponedUntil, intl])

  const isPremiumHealth = useMemo(() => {
    return category && category.metadata.conversationMetaInit?.premiumHealth
  }, [category])

  const isRevisit = useMemo(() => {
    return category && category.metadata.conversationMetaInit?.revisit
  }, [category])

  const categorySymptomBubbleStyle = useMemo(() => {
    if (isPremiumHealth) return styles.premiumHealth
    if (isRevisit) return styles.revisit
    return undefined
  }, [isPremiumHealth, isRevisit])

  const closeModal = () => {
    setShowModal(false)
  }

  const showName = useMemo(
    () => (profile?.type === "child" && profile?.name) || conversation.account?.displayName,
    [conversation.account, profile]
  )

  return (
    <button
      data-testid={`queueConversation-${conversation.id}`}
      className={classNames(styles.conversation, {
        [styles.noBorder]: noBorder
      })}
      id={conversation.id}
      key={conversation.id}
      onClick={() => setShowModal(true)}>
      <div className={styles.nameContainer}>
        {showName && (
          <StaffHeading5 as="p">
            {(profile?.type === "child" && profile?.name) || conversation.account?.displayName}
          </StaffHeading5>
        )}
      </div>

      <div className={styles.body}>
        <div className={classNames(styles.side, styles.left)}>
          {profile?.type === "child" && <ICONS.Child className={styles.childIcon} />}

          <div className={styles.bubbleContainer}>
            <SymptomBubble
              className={categorySymptomBubbleStyle}
              symptom={category}
              appStatic={appStatic}
              cssFilter={categorySymptomBubbleStyle && "brightness(0);"}
            />
            {featureFlags.has("region") && conversation.metadata?.region && (
              <CountryFlag className={styles.regionFlag} countryOrLanguageCode={conversation.metadata.region} />
            )}
          </div>

          <div className={styles.textInfo}>
            <StaffLabelTextXS as="p" className={styles.text}>
              {category?.name}
            </StaffLabelTextXS>

            {conversation.metadata?.subscription && (
              <StaffBodyTextXS className={styles.text}>
                {intl.formatMessage({ id: "label.patient.subscriber" })}
              </StaffBodyTextXS>
            )}

            {featureFlags.has("patient_organization") && conversation.metadata?.organizationId && (
              <StaffBodyTextXS className={styles.text}>
                {intl.formatMessage({ id: "label.patient.organisation.b2b" })}
              </StaffBodyTextXS>
            )}

            {featureFlags.has("language_queues") && conversation.metadata?.language && (
              <StaffBodyTextXS className={styles.text}>
                {intl.formatMessage({ id: `language.${conversation.metadata?.language}` })}
              </StaffBodyTextXS>
            )}
          </div>
        </div>

        <div className={styles.side}>
          {!postponed && (
            <StaffBodyTextXS className={classNames(styles.queueTime, styles[queueTimeClass(time.raw)])}>
              {time.date ? intl.formatDate(time.date, { day: "numeric", month: "short" }) : time.timeLeft}
            </StaffBodyTextXS>
          )}
          {postponed && (
            <StaffBodyTextXS className={classNames(styles.queueTime, styles.primary)}>{postponedUntil}</StaffBodyTextXS>
          )}
        </div>
      </div>
      <div className={styles.location}>
        {clinic && <StaffBodyTextS className={styles.text}>{clinic.name}</StaffBodyTextS>}

        {conversation.metadata?.county && (
          <StaffBodyTextXS className={styles.text}>{countyCodeNames[conversation.metadata.county]}</StaffBodyTextXS>
        )}
      </div>

      <ConfirmDialog
        isOpen={showModal && !assignToUserBlocked}
        message={intl.formatMessage({ id: "assign.from.queue.warning" }, { queue: queueName })}
        onConfirm={() => dispatch(claimConversation(conversation, navigate))}
        confirmText={intl.formatMessage({ id: "btn.continue" })}
        onCancel={closeModal}
      />

      <ConfirmDialog
        isOpen={showModal && assignToUserBlocked}
        message={intl.formatMessage({ id: "conversation.maximum.limit" })}
        onConfirm={closeModal}
        confirmText={intl.formatMessage({ id: "btn.ok" })}
      />
    </button>
  )
}

export default QueueConversationBox
