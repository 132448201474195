import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { v4 as uuidv4 } from "uuid"

import { Button, Dialog, ICONS, StaffBodyTextS } from "@doktor-se/bones-ui"

import { getWebdocConfig } from "api"
import { webdocAuthUrl } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setWebdocAuthState } from "reducers/auth"

interface AuthenticateWithWebdocProps {
  showDialog?: boolean
  setShowDialog?: (value: boolean) => void
}

const AuthenticateWithWebdoc = ({ showDialog = false, setShowDialog }: AuthenticateWithWebdocProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const webdocTokens = useAppSelector(state => state.auth.webdocTokens)

  const [showConfirmAuthentication, setShowConfirmAuthentication] = useState(false)

  const dialogText = useMemo(() => {
    if (showConfirmAuthentication) return intl.formatMessage({ id: "webdoc.authenticate.details" })
    if (showDialog) return intl.formatMessage({ id: "webdoc.reauthenticate.details" }) // TODO add copy to lokalise
  }, [intl, showConfirmAuthentication, showDialog])

  const authenticateToWebdoc = async () => {
    const config = await dispatch(getWebdocConfig())
    if (!config) return
    const state = uuidv4()
    dispatch(setWebdocAuthState(state))
    const path = `${webdocAuthUrl}/oauth/authorize?response_type=code&client_id=${config.clientId}&redirect_uri=${window.location.origin}&scope=patient:write&state=${state}`
    window.location.replace(path)
  }

  const closeDialog = () => {
    setShowConfirmAuthentication(false)
    if (setShowDialog) {
      setShowDialog(false)
    }
  }

  return (
    <>
      {!webdocTokens?.accessToken && (
        <Button variant="primary" color="primary" onPress={() => setShowConfirmAuthentication(true)} small fullWidth>
          {intl.formatMessage({ id: "webdoc.authenticate" })}
        </Button>
      )}

      <Dialog
        isOpen={showConfirmAuthentication || showDialog}
        onClose={closeDialog}
        closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
        fixedWidth>
        <ICONS.Alert />
        <StaffBodyTextS margin={{ bottom: "var(--size-200)", top: "var(--size-200)" }}>{dialogText}</StaffBodyTextS>
        <Button color="primary" variant="primary" onPress={authenticateToWebdoc} fullWidth>
          {intl.formatMessage({ id: "webdoc.authenticate" })}
        </Button>
      </Dialog>
    </>
  )
}

export default AuthenticateWithWebdoc
