import { useMemo, useRef, useState } from "react"
import { AriaPopoverProps, useOverlayTrigger } from "react-aria"
import { useIntl } from "react-intl"
import { OverlayTriggerProps, useOverlayTriggerState } from "react-stately"

import { Button, Dialog, ICONS, StaffBodyTextS } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { fetchSignedUrl, webdocUpload } from "../../../../../../../../api"
import { useAppDispatch } from "../../../../../../../../lib/hooks"
import ActionButton from "../ActionButton"

import styles from "./UploadImagesButton.module.scss"

export interface UploadImagesButtonProps extends OverlayTriggerProps {
  conversation: AssignedConversation
  imagesKeys: (undefined | string)[] | undefined
  buttonSize?: number
  // Popover position props
  placement?: AriaPopoverProps["placement"]
  offset?: AriaPopoverProps["offset"]
  crossOffset?: AriaPopoverProps["crossOffset"]
}

interface ImageData {
  url: string
}

const Progress = ({ value }: { value: number }) => {
  return <progress className={styles.progress} max="100" value={value}></progress>
}

const UploadImagesButton = (props: UploadImagesButtonProps): JSX.Element => {
  const intl = useIntl()
  const { buttonSize, conversation, imagesKeys } = props
  const dispatch = useAppDispatch()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { profile, account } = conversation

  const activePnr = useMemo(() => {
    if (!!profile) return profile.data.securityNumber
    return conversation.account.pnr
  }, [conversation.account.pnr, profile])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [progressValue, setProgressValue] = useState(0)

  const state = useOverlayTriggerState(props)
  const { triggerProps } = useOverlayTrigger({ type: "dialog" }, state, buttonRef)

  const iconSize = buttonSize ? buttonSize * 0.5 : undefined

  const [dialogState, setDialogState] = useState<"start" | "uploading" | "error" | "finish">("start")
  const [failedUploads, setFailedUploads] = useState<ImageData[]>([])

  const uploadToWebdoc = async () => {
    const imagePromises = imagesKeys?.map(async key => {
      return { url: await dispatch(fetchSignedUrl(key!, conversation.id)) } as ImageData
    })
    const imageDataList: ImageData[] = imagePromises ? await Promise.all(imagePromises) : []
    upload(imageDataList)
  }

  const upload = async (imageDataList: ImageData[]) => {
    const tick = 100 / imageDataList.length
    const failed = []
    setDialogState("uploading")
    for (const item of imageDataList) {
      try {
        await dispatch(webdocUpload(item.url, account, false))
      } catch (error) {
        failed.push(item)
      } finally {
        setProgressValue(val => val + tick)
      }
    }
    if (failed.length) {
      setFailedUploads(failed)
      setTimeout(() => {
        setDialogState("error")
      }, 1000)
    } else {
      setTimeout(() => {
        setDialogState("finish")
      }, 1000)
    }
  }

  const openDialog = () => {
    setDialogOpen(true)
    setProgressValue(0)
    setDialogState("start")
  }

  const retry = () => {
    setProgressValue(0)
    upload(failedUploads)
  }

  return (
    <>
      <ActionButton buttonSize={buttonSize} buttonRef={buttonRef} {...triggerProps} onPress={openDialog}>
        <ICONS.Upload height={iconSize} width={iconSize} />
      </ActionButton>

      <Dialog isOpen={dialogOpen} closeAriaLabel={intl.formatMessage({ id: "modal.close" })} fixedWidth>
        {dialogState === "start" && (
          <>
            <StaffBodyTextS style={{ marginBottom: "16px" }}>
              {intl.formatMessage(
                { id: "confirm.webdoc.upload" },
                { name: conversation.account.displayName, pnr: formatPnr(activePnr) }
              )}
            </StaffBodyTextS>
            <div className={styles.dialogButtons}>
              <Button variant="secondary" color="primary" onPress={() => setDialogOpen(false)}>
                {intl.formatMessage({ id: "label.close" })}
              </Button>
              <Button variant="primary" color="primary" onPress={uploadToWebdoc}>
                {intl.formatMessage({ id: "btn.confirm" })}
              </Button>
            </div>
          </>
        )}
        {dialogState === "uploading" && (
          <>
            <StaffBodyTextS style={{ marginBottom: "16px" }}>
              {intl.formatMessage({ id: "journal.webdoc.upload.uploading" })}
            </StaffBodyTextS>
            <Progress value={progressValue} />
          </>
        )}
        {dialogState === "error" && (
          <>
            <StaffBodyTextS style={{ marginBottom: "16px" }}>
              {intl.formatMessage({ id: "journal.webdoc.upload.error" })}
            </StaffBodyTextS>
            <Button variant="primary" color="primary" onPress={retry}>
              {intl.formatMessage({ id: "journal.webdoc.upload.retry" })}
            </Button>
          </>
        )}
        {dialogState === "finish" && (
          <>
            <StaffBodyTextS style={{ marginBottom: "16px" }}>
              {intl.formatMessage({ id: "journal.webdoc.upload.finished" })}
            </StaffBodyTextS>
            <Button variant="primary" color="primary" onPress={() => setDialogOpen(false)}>
              {intl.formatMessage({ id: "label.close" })}
            </Button>
          </>
        )}
      </Dialog>
    </>
  )
}

export default UploadImagesButton
