import { useIntl } from "react-intl"

import { StaffBodyTextXXS } from "@doktor-se/bones-ui"

import { SearchPageConversation } from "types"

interface StateTextProps {
  conversation: SearchPageConversation
}

const getMessageId = (conversation: SearchPageConversation) => {
  if (conversation.state === "closed") {
    return "label.closed"
  }

  if (conversation.metadata?.isUpcomingBooking || conversation.state === "booked") {
    return "label.booked"
  }

  if (conversation.state === "opened") {
    return "label.open"
  }

  return null
}

export const StateText = ({ conversation }: StateTextProps) => {
  const intl = useIntl()
  const messageId = getMessageId(conversation)

  return <StaffBodyTextXXS>{messageId ? intl.formatMessage({ id: messageId }) : ""}</StaffBodyTextXXS>
}
