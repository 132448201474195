import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"
import { Dayjs } from "dayjs"

import { Button, ICONS, StaffHeading3, StaffHeading5, StaffLabelTextS, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppSelector } from "lib/hooks"
import { queueSelectors } from "reducers/queue/queue.reducer"

import PostponeTimes from "components/ToQueueDialog/components/ToQueueStaff/PostponeTimes"
import { useQueueIsOpen } from "components/ToQueueDialog/components/ToQueueStaff/useQueueIsOpen"

import { ConfirmParams } from "../PhysicalToDigitalDialog"

import styles from "./ToDigitalQueue.module.scss"

const sliceQueueName = (queueName: string) => {
  if (queueName.length > 30) {
    return queueName.slice(0, 30) + "..."
  }
  return queueName
}

interface ToDigitalQueueProps {
  conversationId: string
  selectedQueue: Queue | undefined
  setSelectedQueue: (queue: Queue) => void
  onSend: (params: ConfirmParams) => void
}

export const ToDigitalQueue = ({ conversationId, selectedQueue, setSelectedQueue, onSend }: ToDigitalQueueProps) => {
  const intl = useIntl()
  const appLanguage = useAppSelector(state => state.app.language)
  const queues = useAppSelector(queueSelectors.selectAll)
  const availableQueues = useMemo(() => {
    return queues.filter(q => q.isDigital && q.isPossibleToReassign)
  }, [queues])
  const queueOpeningHours = useAppSelector(state => state.app.queueOpeningHours)

  const [dateFromSlotSelection, setDateFromSlotSelection] = useState<Dayjs>()
  const [nowSelected, setNowSelected] = useState(true)
  const queueIsOpen = useQueueIsOpen(queueOpeningHours, selectedQueue)

  const sendToQueue = () => {
    const postponedTime = dateFromSlotSelection?.format()
    onSend({ postponed: postponedTime })
  }

  return (
    <>
      <div className={styles.queues}>
        <StaffHeading5 className={styles.queuesTitle}>{intl.formatMessage({ id: "label.queues" })}</StaffHeading5>
        {availableQueues.map(q => (
          <button
            key={q.id}
            onClick={() => setSelectedQueue(q)}
            className={classnames(styles.queueButton, {
              [styles.queueButtonSelected]: selectedQueue && selectedQueue.queueKey === q.queueKey
            })}>
            <StaffLabelTextXS>{sliceQueueName(q.translations[appLanguage])}</StaffLabelTextXS>
            <ICONS.ChevronRight width={20} height={20} />
          </button>
        ))}
      </div>
      {!!selectedQueue && (
        <div className={styles.postpone}>
          <StaffHeading3 className={styles.heading} margin={{ bottom: "var(--size-200)" }}>
            {intl.formatMessage({ id: "postpone.title" })}
          </StaffHeading3>
          <Button
            variant={!!dateFromSlotSelection ? "outline" : "primary"}
            small
            color="primary"
            onPress={() => {
              setDateFromSlotSelection(undefined)
              setNowSelected(true)
            }}>
            {intl.formatMessage({
              id: queueIsOpen === true ? "postpone.now" : "unassign.postpone.when.open"
            })}
          </Button>

          {typeof queueIsOpen === "string" && (
            <StaffLabelTextS className={styles.queueOpenTime} margin="var(--size-100) var(--size-400)">
              {queueIsOpen}
            </StaffLabelTextS>
          )}
          <PostponeTimes
            conversationId={conversationId}
            setDate={setDateFromSlotSelection}
            nowSelected={nowSelected}
            setNowSelected={setNowSelected}
            selectedQueue={selectedQueue.queueKey}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button isDisabled={!selectedQueue} variant="primary" fullWidth color="primary" onPress={sendToQueue}>
          {intl.formatMessage({ id: "label.send" })}
        </Button>
      </div>
    </>
  )
}
