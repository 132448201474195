import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { StaffBodyTextS, StaffBodyTextXXS } from "@doktor-se/bones-ui"
import { CustomerMetadata } from "@doktor-se/bones-ui/dist/web-shared/types"

import { IconFailure, IconGreenTick } from "icons"

import styles from "./PaymentsMetadata.module.scss"

const PaymentsMetadata = (props: { metadata: CustomerMetadata }) => {
  const payment = props.metadata.payment
  if (!payment) {
    return null
  }

  const PaymentsRow = (props: { textKey: string; Icon: React.ElementType; extraData?: string }) => {
    const { textKey, Icon, extraData } = props
    const intl = useIntl()

    return (
      <div className={styles.container}>
        <StaffBodyTextS margin={{ bottom: "4px" }} className={styles.heading}>
          {intl.formatMessage({ id: "patient.info.payment" })}
        </StaffBodyTextS>
        <div className={styles.text}>
          <span className={styles.paymentStatusWrapper}>
            <StaffBodyTextS as="span">{intl.formatMessage({ id: textKey })}</StaffBodyTextS>
            <Icon className={styles.paymentIcon} />
          </span>
        </div>
        {extraData && <StaffBodyTextXXS>{extraData}</StaffBodyTextXXS>}
      </div>
    )
  }

  if (payment.status === "SUCCEEDED") {
    return (
      <PaymentsRow
        textKey="patient.info.payment.completed"
        Icon={IconGreenTick}
        extraData={dayjs(payment.succeededAt).format("YYYY-MM-DD HH:mm")}
      />
    )
  } else if (payment.status === "FAILED") {
    return (
      <PaymentsRow
        textKey="patient.info.payment.failed"
        Icon={IconFailure}
        extraData={payment.failedAt && dayjs(payment.failedAt).format("YYYY-MM-DD HH:mm")}
      />
    )
  } else {
    return null
  }
}

export default PaymentsMetadata
