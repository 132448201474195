import { useCallback, useEffect, useState } from "react"

import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

import { groupCategories } from "lib/format"
import { useAppSelector, useSubcategories } from "lib/hooks"
import { selectCategoriesPerRole } from "reducers/categories"

import SearchCategories from "./SearchCategories"

interface SearchCategoriesWrapperProps {
  onSelectCategory: (category: Category) => void
  filterCategoriesForProfile?: boolean
}

const filterSubCategories = (categories: Category[], profileType: "profile" | "client") =>
  categories.reduce(
    (acc, category) => {
      const children = categories.filter(c => c.parentId === category.id && c.metadata.patientType === profileType)
      if (!!children.length) acc[category.id] = groupCategories(children)
      return acc
    },
    {} as { [category: number]: GroupedCategories[] }
  )

const SearchCategoriesWrapper = ({
  onSelectCategory,
  filterCategoriesForProfile = false
}: SearchCategoriesWrapperProps) => {
  const categories = useAppSelector(selectCategoriesPerRole)
  const [filteredCategories, setFilteredCategories] = useState<GroupedCategories[]>([])
  const [filteredSubcategories, setFilteredSubcategories] = useState<{ [categoryId: number]: GroupedCategories[] }>({})
  const subcategories = useSubcategories(categories)
  const [selectedParentCategory, setSelectedParentCategory] = useState<Category>()
  const filterCategories = useCallback(() => {
    const patientType = filterCategoriesForProfile ? "profile" : "client"
    const filtered = categories.filter(c => c.metadata.patientType === patientType)
    const filteredSub = filterSubCategories(categories, patientType)
    setFilteredSubcategories(filteredSub)
    setFilteredCategories(
      groupCategories(filtered.filter(c => !c.parentId && !Object.keys(filteredSub).includes(c.id.toString())))
    )
  }, [categories, filterCategoriesForProfile])

  useEffect(() => {
    filterCategories()
  }, [filterCategories])

  const categoryOnClick = (category: Category) => {
    if (subcategories[category.id]) {
      setFilteredCategories(subcategories[category.id])
      setSelectedParentCategory(category)
    } else {
      onSelectCategory(category)
    }
  }

  const closeSubcategory = () => {
    filterCategories()
    setSelectedParentCategory(undefined)
  }

  return (
    <SearchCategories
      categories={filteredCategories}
      subcategories={Object.keys(filteredSubcategories).map(id => categories.find(c => c.id.toString() === id)!)}
      changeCategory={category => categoryOnClick(category)}
      closeSubcategory={closeSubcategory}
      selectedParentCategory={selectedParentCategory}
    />
  )
}

export default SearchCategoriesWrapper
