import { useState } from "react"
import { useIntl } from "react-intl"

import { Button, Dialog, ICONS, StaffBodyTextS, StaffHeading1 } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { logout } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { registerNewUser } from "lib/seald"

import styles from "./DeviceSwitchDialog.module.scss"

const DeviceSwitchDialog = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const [isLoading, setLoading] = useState(false)
  const browserSwitch = useAppSelector(state => state.encryption.browserSwitch)
  const userId = useAppSelector(state => state.auth.user?.id)

  const onConfirm = () => {
    setLoading(true)
    userId && dispatch(registerNewUser(userId, browserSwitch.alreadyRegistered))
  }

  return (
    <Dialog isOpen={browserSwitch.browserSwitch} closeAriaLabel={"This is label"} fixedWidth={true}>
      <ICONS.Warning />
      <StaffHeading1 margin={{ bottom: "10px" }}>
        {intl.formatMessage({ id: "conversation.encryption.warning.headline" })}
      </StaffHeading1>
      <StaffBodyTextS margin={{ bottom: "10px" }} as="span">
        {intl.formatMessage({ id: "conversation.encryption.warning.content" })}
      </StaffBodyTextS>
      {isLoading ? <Loader type="small" /> : undefined}
      <button className={styles.deviceSwitchDialogButton} onClick={onConfirm}>
        {intl.formatMessage({ id: "conversation.encryption.warning.button_label" })}
      </button>
      <Button color="primary" small variant="outline" onPress={() => dispatch(logout())}>
        {intl.formatMessage({ id: "conversation.encryption.warning.logout_label" })}
      </Button>
    </Dialog>
  )
}

export default DeviceSwitchDialog
