import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek"
import timezone from "dayjs/plugin/timezone"

import { Dialog } from "@doktor-se/bones-ui"
import { AssignedConversation, CallEndReason, Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { assignToQueue } from "api"
import { approvedClinicsForClinicQueue as approvedClinics, featureFlags } from "config"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { getConversationClinic } from "lib/utils"
import { clinicSelectors } from "reducers/clinics"
import { SearchPageConversation } from "types"

import BlockedToQueue from "./components/BlockedToQueue/BlockedToQueue"
import ConfirmToQueue from "./components/ConfirmToQueue/ConfirmToQueue"
import ToQueueStaff from "./components/ToQueueStaff/ToQueueStaff"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

dayjs.extend(timezone)
dayjs.extend(isoWeek)

export type Day = "today" | "tomorrow" | undefined

export interface ToQueueDialogProps {
  isOpen: boolean
  conversation: AssignedConversation | SearchPageConversation
  onClose: () => void
  offset?: Offset
}

const ToQueueDialog = ({ isOpen, conversation, offset, onClose }: ToQueueDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const appLanguage = useAppSelector(state => state.app.language)
  const clinics = useAppSelector(clinicSelectors.selectAll)
  const clinic: Clinic | undefined = useMemo(() => {
    const metadata = {
      platform: conversation.metadata,
      customer: conversation.customerMetadata
    }
    return getConversationClinic({ clinics, metadata })
  }, [clinics, conversation])

  const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>(clinic)

  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>()
  const [dialogState, setDialogState] = useState<"unassign" | "confirm" | "blocked">("unassign")
  const [blockedMsg, setBlockedMsg] = useState<string>()
  const [postponed, setPostponed] = useState<string>()

  const handleUnassign = (queue: Queue, postponed?: string, selectedClinicId?: string) => {
    setPostponed(postponed)
    const newClinic = selectedClinicId ? clinics.find((c: Clinic) => c.id === selectedClinicId) : undefined
    setSelectedClinic(newClinic)

    if (!featureFlags.has("to_queue_select_clinic")) {
      if (queue.queueKey === "external_nurse" && !newClinic && featureFlags.has("clinic_tag")) {
        setDialogState("blocked")
        setBlockedMsg(intl.formatMessage({ id: "unassign.clinic.not.set" }))
      } else if (
        queue.queueKey === "external_nurse" &&
        !!newClinic &&
        !approvedClinics.map(clinic => clinic.toLowerCase()).includes(newClinic.name.toLowerCase()) &&
        featureFlags.has("clinic_tag")
      ) {
        setDialogState("blocked")
        setBlockedMsg(intl.formatMessage({ id: "unassign.clinic.not.open" }))
      } else {
        setDialogState("confirm")
      }
    } else {
      setDialogState("confirm")
    }
  }

  const onCloseDialog = () => {
    onClose()
    setDialogState("unassign")
  }

  const onCancel = () => {
    setSelectedClinic(clinic)
    setDialogState("unassign")
  }

  const unassignConfirmed = (queue: string, postponed?: string, clinicId?: string) => {
    let reason: CallEndReason | undefined
    if (conversation.callState !== "incoming") reason = checkDeclineCall(conversation)
    dispatch(assignToQueue(conversation, queue, postponed, reason, clinicId))
    onCloseDialog()
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      {dialogState === "unassign" && (
        <ToQueueStaff
          selectedQueue={selectedQueue}
          setSelectedQueue={setSelectedQueue}
          onConfirm={handleUnassign}
          onCancel={onClose}
          conversationId={conversation.id}
        />
      )}

      {dialogState === "blocked" && blockedMsg && <BlockedToQueue message={blockedMsg} onConfirm={onCloseDialog} />}

      {dialogState === "confirm" && selectedQueue && (
        <ConfirmToQueue
          message={`${intl.formatMessage(
            { id: "unassign.confirm.message" },
            { queue: selectedQueue.translations[appLanguage] }
          )}${
            !!selectedClinic
              ? `\n\n${intl.formatMessage(
                  {
                    id: "control.unassign.clinic.to"
                  },
                  { clinic: selectedClinic.name }
                )}`
              : ""
          }${
            conversation.callState !== "inactive"
              ? `\n\n${intl.formatMessage({
                  id: "control.call.active.unassign"
                })}`
              : ""
          }${
            !!postponed
              ? `\n\n${intl.formatMessage(
                  {
                    id: "control.unassign.postpone.to"
                  },
                  { date: dayjs(postponed).format("Do MMM HH:mm") }
                )}`
              : ""
          }
        `}
          onCancel={() => onCancel()}
          onConfirm={() => unassignConfirmed(selectedQueue.queueKey, postponed, selectedClinic?.id)}
        />
      )}
    </Dialog>
  )
}

export default ToQueueDialog
